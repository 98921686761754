import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Layout from '../templates/Page';
import BlogPage from '../templates/Layout/BlogPage';
import NotFound from './404';
import queryString from 'query-string';

const PREVIEW_QUERY = gql`
    query getPreview($id: Int!) {
        postBy(postId: $id) {
            title
            revisions {
                nodes {
                    id
                    title
                    content
                    categories {
                        nodes {
                            name
                        }
                    }
                    featuredImage {
                        link
                    }
                }
            }
        }
    }
`;

const PostPreviewPage = (props) => {
    const parsed = queryString.parse(props.location.search);
    
    const {
      nonce,
      preview_id,
    } = parsed;

    const intId = parseInt(preview_id, 10);

    
      return (
            <Query
            query={PREVIEW_QUERY}
            variables={{
                id: intId,
                nonce,
            }}
            >
                {({ data, loading, error }) => {
                    if (loading) {
                        return <p>Loading preview...</p>;
                    }
                    if (error) {
                        return <p>Error: ${error.message}</p>;
                    }
                    if (!data.postBy.revisions.nodes.length) {
                        return <NotFound />
                    }

                    const preview = data.postBy.revisions.nodes[0];

                    let category = '';
                    let backgroundSrc = '';
                    if (preview.categories.nodes.length) {
                        category = preview.categories.nodes[0].name;
                    }
                    if (preview.featuredImage) {
                        backgroundSrc = preview.featuredImage.link;
                    }
                    const props = {
                        data: {
                            post: {
                                title: preview.title,
                                content: preview.content,
                                wordpress_id: preview.id,
                                fields: {
                                    summary: '',
                                    category,
                                    link: preview.link,
                                },
                                backgroundSrc,
                            }
                        }
                    };

                    return <BlogPage {...props} />
                }}
            </Query>
    );
};

export default PostPreviewPage;